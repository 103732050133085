// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDe5_wBLUqmAx-q2-Cl78Sxqqt0qVQmp7E",
  authDomain: "animaltaitsuiconmaker.firebaseapp.com",
  projectId: "animaltaitsuiconmaker",
  storageBucket: "animaltaitsuiconmaker.appspot.com",
  messagingSenderId: "126878864839",
  appId: "1:126878864839:web:281306545846a4927db5e2",
  measurementId: "G-LR30BZZWPN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };