<template>
  <div class="flex flex-col justify-center space-x-4 mb-8">
    <div class="mb-8">
      <button @click="downloadImage('jpeg')" class="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded">
        ダウンロード
      </button>
      <!-- <button @click="downloadImage('jpeg')" class="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded">
        JPEG ダウンロード
      </button> -->
      <!-- <button @click="downloadImage('gif')" class="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded">
        GIF ダウンロード
      </button> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageDownload',
  props: {
    decorationImage: String,
    patternImage: String,
    basePatternImage: String,
    baseImage: String,
    bcPatternImage: String,
    backgroundImage: String,
  },
  data() {
    return {
      downloading: false,
      taitsuId: '',
    };
  },
  methods: {
    async downloadImage(format) {
      this.downloading = true;
      this.$emit('update:downloading', true);

      // GA4でのイベントトラッキング
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'download', {
          'event_category': 'button',
          'event_label': 'downloadImg',
          'value': 1
        });
      }
      
      // タイムアウトを監視するPromiseを設定
      const timeoutPromise = new Promise((_, reject) => 
        setTimeout(() => reject(new Error('処理がタイムアウトしました。')), 3000));


      try {
        // canvas要素を作成
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        
        // canvasのサイズを設定（背景画像に合わせる）
        canvas.width = 800; // 実際の画像サイズに合わせて調整が必要
        canvas.height = 800; // 実際の画像サイズに合わせて調整が必要

        const loadImagePromises = [];
      
        // 背景画像を読み込んで描画
        if (this.backgroundImage) {
          loadImagePromises.push(this.drawImage(ctx, '/images/taitsu-maker/background/' + this.backgroundImage, 0, 0, canvas.width, canvas.height));
        }

        // 背景柄画像を読み込んで描画
        if (this.bcPatternImage) {
          loadImagePromises.push(this.drawImage(ctx, '/images/taitsu-maker/bcPattern/' + this.bcPatternImage, 0, 0, canvas.width, canvas.height));
        }
        
        // ベース画像を読み込んで描画
        if (this.baseImage) {
          loadImagePromises.push(this.drawImage(ctx, '/images/taitsu-maker/base/' + this.baseImage, 0, 0, canvas.width, canvas.height));
        }

        // 柄画像を読み込んで描画
        if (this.basePatternImage) {
          loadImagePromises.push(this.drawImage(ctx, '/images/taitsu-maker/basePattern/' + this.basePatternImage, 0, 0, canvas.width, canvas.height));
        }

        // 動物画像を読み込んで描画
        if (this.patternImage) {
          loadImagePromises.push(this.drawImage(ctx, '/images/taitsu-maker/pattern/' + this.patternImage, 0, 0, canvas.width, canvas.height));
        }

        // 飾り画像を読み込んで描画
        if (this.decorationImage) {
          loadImagePromises.push(this.drawImage(ctx, '/images/taitsu-maker/decoration/' + this.decorationImage, 0, 0, canvas.width, canvas.height));
        }
        
        await Promise.race([Promise.all(loadImagePromises), timeoutPromise]);
          
        const mimeType = format === 'gif' ? 'image/gif' : 'image/jpeg';
        const fileExtension = format === 'gif' ? 'gif' : 'jpg';
        const fileName = `animal-taitsu-icon.${fileExtension}`;

        // canvasの内容を画像としてダウンロード
        canvas.toBlob(blob => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName; // ダウンロードするファイル名
          a.click();
          URL.revokeObjectURL(url);
          this.showSuccessMessage();
        }, mimeType);
      } catch (error) {
        this.showErrorMessage();
      } finally {
        this.downloading = false;
        this.$emit('update:downloading', false);
      }
    },
    drawImage(ctx, src, x, y, width, height) {
      return new Promise(resolve => {
        const image = new Image();
        image.onload = () => {
          ctx.drawImage(image, x, y, width, height);
          resolve();
        };
        image.src = src;
      });
    },
    showSuccessMessage() {
      alert('ダウンロードに成功したよ！');
    },
    showErrorMessage() {
      alert('ダウンロードに失敗しました。');
    }
  }
}
</script>
