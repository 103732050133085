class AnnivTakarakujiImageLists {
  constructor(
    taitsuId, timestamp,
    currentPatternIndex = 0, currentBackgroundIndex = 0, currentBaseIndex = 0,
    currentDecorationIndex = 0, currentBcPatternIndex = 0, currentBasePatternIndex = 0
  ) {
    this.taitsuId = taitsuId;
    this.timestamp = timestamp;
    this.currentPatternIndex = currentPatternIndex;
    this.currentBackgroundIndex = currentBackgroundIndex;
    this.currentBaseIndex = currentBaseIndex;
    this.currentDecorationIndex = currentDecorationIndex;
    this.currentBcPatternIndex = currentBcPatternIndex;
    this.currentBasePatternIndex = currentBasePatternIndex;
  }

  // 組み合わせ：動物32 * 背景15 * タイツ16 * 飾り2 * 風船6 = 92,160
  incrementImageNum(latestRecord, maxValues) {
    // start 00000000
    // 00000001, 00000002, ... 00000031, 00000032, ... 00000100, ... 00000200, ... 00001532, 00010000, ...
    // end 51151431

    // firebaseにはIDを保存する（decorationImages、patternImages、basePatternImages、baseImages、bcPatternImages、backgroundImages）
    // 1人目は全部0から始まる
    // 組み合わせはユニーク。
    // patternImagesを+1していき、最大値に達したらbackgroundImagesを+1する。patternImages、backgroundImages、baseImages、decorationImages、bcPatternImages、basePatternImagesの順に繰り上がる
    // 2人目以降は、最新のレコードを参照してそれに対して+1していく
    // 組み合わせの最大値に達したら宝くじは終了

    // 最新のレコードの値を使用してカウントを初期化
    this.currentPatternIndex = latestRecord.currentPatternIndex || 0;
    this.currentBackgroundIndex = latestRecord.currentBackgroundIndex || 0;
    this.currentBaseIndex = latestRecord.currentBaseIndex || 0;
    this.currentDecorationIndex = latestRecord.currentDecorationIndex || 0;
    this.currentBcPatternIndex = latestRecord.currentBcPatternIndex || 0;
    this.currentBasePatternIndex = latestRecord.currentBasePatternIndex || 0;

    // 動物
    if (this.currentPatternIndex < maxValues.patternImages) {
      this.currentPatternIndex += 1;
    } else {
      this.currentPatternIndex = 0;
      // 背景
      if (this.currentBackgroundIndex < maxValues.backgroundImages) {
        this.currentBackgroundIndex += 1;
      } else {
        this.currentBackgroundIndex = 0;
        // タイツ
        if (this.currentBaseIndex < maxValues.baseImages) {
          this.currentBaseIndex += 1;
        } else {
          this.currentBaseIndex = 0;
          // 飾り
          if (this.currentDecorationIndex < maxValues.decorationImages) {
            this.currentDecorationIndex += 1;
          } else {
            this.currentDecorationIndex = 0;
            // 風船
            if (this.currentBcPatternIndex < maxValues.bcPatternImages) {
              this.currentBcPatternIndex += 1;
            } else {
              // 全ての組み合わせを超えたら終了
              return false;
            }
          }
        }
      }
    }
    return true;
  }
}
export default AnnivTakarakujiImageLists;