<template>
  <header class="header">
    <div class="header-container">
      <div class="header-content">
        <img :src="pageIcon" alt="Icon" class="page-icon">
        <h1 class="header-title">
          {{ pageTitle }}
        </h1>
      </div>
      <p class="header-author">
        by <a class="header-link text-gray-800" href="https://taittsuu.com/users/muguet" target="blank">たやまゆうり</a>
      </p>
      <nav class="header-nav">
        <router-link :to="{ name: 'TaitsuMaker' }" class="header-nav-link">アイコンメーカー</router-link>
        <router-link :to="{ name: 'Omikuji' }" class="header-nav-link">おみくじ</router-link>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  computed: {
    pageTitle() {
      const routeName = this.$route.name;
      switch (routeName) {
        case 'TaitsuMaker':
          return 'アニマルタイツアイコンメーカー';
        case 'Omikuji':
          return 'アニマルタイツ神社';
        default:
          return 'Yuuri Tayama\'s Fun Box';
      }
    },
    pageIcon() {
      const routeName = this.$route.name;
      switch (routeName) {
        case 'TaitsuMaker':
          return '/images/taitsu-maker/icon.png';
        case 'Omikuji':
          return '/images/taitsu-maker/omikuji/torii-red01.png';
        default:
          return '/images/taitsu-maker/icon.png';
      }
    }
  }
}
</script>

<style scoped>
.header {
  width: 100%;
  background-color: #f8f9fa;
  padding: 1rem 0;
}

.header-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  text-align: center;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.0rem;
}

.page-icon {
  width: 50px;
  height: 50px;
  margin-right: 0.5rem;
}

.header-title {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
}

.header-author {
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.header-link {
  text-decoration: underline;
}

.header-nav {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.header-nav-link {
  text-decoration: none;
  font-size: 1rem;
}

.header-nav-link:hover {
  text-decoration: underline;
}

/* レスポンシブ対応 */
@media (max-width: 768px) {
  .page-icon {
    width: 30px;
    height: 30px;
  }

  .header-title {
    font-size: 1.0rem;
  }

  .header-author {
    font-size: 0.75rem;
    margin-bottom: 1rem;
  }
}
</style>