<template>
<div>
  <div class="flex justify-center space-x-4 mb-4">
    <a :href="getShareLink('taittsuu')"
      target="_blank"
      class="inline-flex items-center bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
      <img src="/images/taitsu-maker/kari_icon.png" alt="Share" class="w-4 h-4 mr-2">
      タイーツする
    </a>
    <a :href="getShareLink('x')"
      target="_blank"
      class="inline-flex items-center bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded">
      Xでシェアする
    </a>
  </div>
  <div class="flex justify-center space-x-4 mb-12 text-xs">
    <p>※シェアするときに画像は自動でつかないので添付してください🙏</p>
  </div>
</div>
</template>

<script>
export default {
  name: 'Share',
  props: {
    shareMessage: String
  },
  methods: {
    getShareLink(sns) {
      const route = this.$router.resolve({ name: 'Omikuji' }).href;
      let baseLink;
      if (sns === "taittsuu") {
        baseLink = "https://taittsuu.com/share?text=";

      } else {
        baseLink = "https://twitter.com/intent/tweet?text=";
      }
      const message = encodeURIComponent(this.shareMessage);
      const hashtag1 = encodeURIComponent("#アニマルタイツアイコンメーカー");
      const hashtag2 = encodeURIComponent("#アニマルタイツおみくじ");
      const url = encodeURIComponent(`https://fun.yuuritayama.com${route}`);
      return `${baseLink}${message}%0A%0A${hashtag1}%0A${hashtag2}%0A${url}`;
    }
  }
}
</script>
