import ImageLists from '@/models/TaitsuMaker/ImageLists.js';


// 秋
const autumnPatternImages = [];
const autumnDecorationImages = [];
const autumnBasePatternImages = [];
const autumnBaseImages = [];
const autumnBcPatternImages = ['bkp-autumn-01.png', 'bkp-autumn-02.png'];
const autumnBackgroundImages = [];
const autumnOmikujiDaishiImages = [];
for (let i = 1; i <= 6; i++) {
  autumnBaseImages.push(`base-autumn-${i.toString().padStart(2, '0')}.png`);
}
for (let i = 1; i <= 6; i++) {
  autumnBackgroundImages.push(`bc-autumn-${i.toString().padStart(2, '0')}.png`);
}
for (let i = 1; i <= 3; i++) {
  autumnOmikujiDaishiImages.push(`omikuji-daishi-autumn-${i.toString().padStart(2, '0')}.png`);
}
const AutumnEvent = new ImageLists(
  'AutumnEvent',
  '2024-09-01',
  '2024-11-30',
  true,
  '9/1～11/30期間限定 秋限定タイツがはけるよ🍁',
  'bg-red-200 text-red-900',
  '🍁期間限定🍁',
  'bg-red-200 text-red-900',
  autumnDecorationImages,
  autumnPatternImages,
  autumnBasePatternImages,
  autumnBaseImages,
  autumnBcPatternImages,
  autumnBackgroundImages,
  autumnOmikujiDaishiImages
);

// お月見
const otsukimiPatternImages = [];
const otsukimiDecorationImages = [];
const otsukimiBasePatternImages = [];
const otsukimiBaseImages = [];
const otsukimiBcPatternImages = [];
const otsukimiBackgroundImages = [];
for (let i = 1; i <= 2; i++) {
  otsukimiDecorationImages.push(`deco-otsukimi-${i.toString().padStart(2, '0')}.png`);
}
for (let i = 1; i <= 2; i++) {
  otsukimiBasePatternImages.push(`bp-otsukimi-${i.toString().padStart(2, '0')}.png`);
}
for (let i = 1; i <= 4; i++) {
  otsukimiBackgroundImages.push(`bc-otsukimi-${i.toString().padStart(2, '0')}.png`);
}
const OtsukimiEvent = new ImageLists(
  'OtsukimiEvent',
  '2024-09-01',
  '2024-09-30',
  true,
  '9/1～9/30期間限定 お月見タイツがはけるよ🌕',
  'bg-yellow-200 text-yellow-900',
  '🌕期間限定🌕',
  'bg-yellow-200 text-yellow-900',
  otsukimiDecorationImages,
  otsukimiPatternImages,
  otsukimiBasePatternImages,
  otsukimiBaseImages,
  otsukimiBcPatternImages,
  otsukimiBackgroundImages,
  ['omikuji-daishi-otsukimi-01.png']
);

// 夏
const summerPatternImages = [];
const summerDecorationImages = [];
const summerBasePatternImages = [];
const summerBaseImages = [];
const summerBcPatternImages = [];
const summerBackgroundImages = [];
for (let i = 1; i <= 8; i++) {
  summerDecorationImages.push(`summer-deco-${i.toString().padStart(2, '0')}.png`);
}
for (let i = 1; i <= 4; i++) {
  summerBaseImages.push(`summer-base-${i.toString().padStart(2, '0')}.png`);
}
for (let i = 1; i <= 7; i++) {
  summerBcPatternImages.push(`summer-bp-${i.toString().padStart(2, '0')}.png`);
}
for (let i = 1; i <= 4; i++) {
  summerBackgroundImages.push(`summer-bk-${i.toString().padStart(2, '0')}.png`);
}
const SummerEvent = new ImageLists(
  'SummerEvent',
  '2024-07-01',
  '2024-08-31',
  true,
  '7/1～8/31期間限定 夏限定タイツがはけるよ🌺',
  'bg-pink-200 text-pink-900',
  '🌺期間限定🌺',
  'bg-pink-200 text-pink-900',
  summerDecorationImages,
  summerPatternImages,
  summerBasePatternImages,
  summerBaseImages,
  summerBcPatternImages,
  summerBackgroundImages,
  []
);

// 1周年イベント
const annivPatternImages = [];
const annivDecorationImages = ['anniv-01.png', 'anniv-02.png'];
const annivBasePatternImages = [];
const annivBaseImages = [];
const annivBcPatternImages = [];
const annivBackgroundImages = [];
for (let i = 1; i <= 6; i++) {
  annivBcPatternImages.push(`bc-anniv-${i.toString().padStart(2, '0')}.png`);
}
const firstAnnivEvent = new ImageLists(
  'firstAnnivEvent',
  '2024-07-01',
  '2024-07-10',
  false,
  '7/1～7/10期間限定 タイッツー1周年記念タイツがはけるよ🎊',
  'bg-pink-200 text-pink-900',
  '🎊期間限定🎊',
  'bg-pink-200 text-pink-900',
  annivDecorationImages,
  annivPatternImages,
  annivBasePatternImages,
  annivBaseImages,
  annivBcPatternImages,
  annivBackgroundImages,
  []
);
const firstAnnivTakarakujiEvent = new ImageLists(
  'FirstAnnivTakarakujiEvent',
  '2024-07-01',
  '2024-07-03',
  false,
  '#アニマルタイツアイコン宝くじ 実施中✨',
  'bg-pink-200 text-pink-900',
  '✨期間限定✨',
  'bg-pink-200 text-pink-900',
  [],
  [],
  [],
  [],
  [],
  [],
  []
);

// 梅雨イベント
const tsuyuPatternImages = [];
const tsuyuDecorationImages = [];
const tsuyuBasePatternImages = ["base-tsuyu-hasu1.png", "base-tsuyu-hasu2.png"];
const tsuyuBaseImages = [];
const tsuyuBcPatternImages = [];
const tsuyuBackgroundImages = [];
for (let i = 1; i <= 7; i++) {
  tsuyuPatternImages.push(`tsuyu-kaeru${i.toString().padStart(2, '0')}.png`);
}
tsuyuPatternImages.push('tsuyu-katatsumuri01.png', 'tsuyu-katatsumuri02.png');
for (let i = 1; i <= 6; i++) {
  tsuyuBcPatternImages.push(`tsuyu-ajisai${i.toString().padStart(2, '0')}.png`);
}
tsuyuBcPatternImages.push('tsuyu-rain01.png', 'tsuyu-rain02.png');
const tsuyuEvent = new ImageLists(
  'TsuyuEvent',
  '2024-06-01',
  '2024-06-30',
  true,
  '🐸6/1～6/30期間限定🐸 梅雨タイツがはけるよ✨',
  'bg-green-200 text-green-900',
  '🐸期間限定🐸',
  'bg-green-200 text-green-900',
  tsuyuDecorationImages,
  tsuyuPatternImages,
  tsuyuBasePatternImages,
  tsuyuBaseImages,
  tsuyuBcPatternImages,
  tsuyuBackgroundImages,
  []
);

// こどもの日に関連する画像配列の作成
const kodomonohiPatternImages = [];
const kodomonohiDecorationImages = [];
const kodomonohiBasePatternImages = [];
const kodomonohiBaseImages = [];
const kodomonohiBcPatternImages = [];
const kodomonohiBackgroundImages = [];
// 鯉のぼり関連画像
for (let i = 1; i <= 17; i++) {
    kodomonohiPatternImages.push(`koinobori${i.toString().padStart(2, '0')}.png`);
    kodomonohiDecorationImages.push(`koinobori-l${i.toString().padStart(2, '0')}.png`);
}
// 柏餅画像の追加
kodomonohiBasePatternImages.push('kashiwamochi01.png', 'kashiwamochi02.png');
// こどもの日柄画像
for (let i = 1; i <= 9; i++) {
    kodomonohiBasePatternImages.push(`kodomonohi${i.toString().padStart(2, '0')}.png`);
}
// こどもの日イベント
const kodomonohiEvent = new ImageLists(
  'KodomonohiEvent',
  '2024-04-18',
  '2024-05-12',
  true,
  '🎏4/21～5/12期間限定🎏 こどもの日タイツがはけるよ✨',
  'bg-blue-200 text-blue-900',
  '🎏期間限定🎏',
  'bg-blue-200 text-blue-900',
  kodomonohiDecorationImages,
  kodomonohiPatternImages,
  kodomonohiBasePatternImages,
  kodomonohiBaseImages,
  kodomonohiBcPatternImages,
  kodomonohiBackgroundImages,
  []
);

// イースターイベント
const easterEvent = new ImageLists(
    'EasterEvent',
    '2024-03-24',
    '2024-04-07',
    true,
    '🐇3/24～4/7期間限定🐇 イースタータイツがはけるよ🥚',
    'bg-yellow-200 text-yellow-900',
    '🐇期間限定🐇',
    'bg-yellow-200 text-yellow-900',
    [],
    ['easter01.png', 'easter02.png', 'easter03.png', 'easter04.png', 'easter05.png'],
    ['base-easter01.png', 'base-easter02.png', 'base-easter03.png', 'base-easter04.png', 'base-easter05.png', 'base-easter06.png'],
    [],
    ['bc-rabit01.png', 'bc-rabit02.png', 'bc-rabit03.png'],
    [],
    []
);

// 春のイベント
const springEvent = new ImageLists(
    'SpringEvent',
    '2024-03-01',
    '2024-04-30',
    true,
    '🌸3/1～4/30期間限定🌸 春模様タイツがはけるよ✨',
    'bg-pink-200 text-pink-900',
    '🌸期間限定🌸',
    'bg-pink-200 text-pink-900',
    [],
    [],
    ['base-sakura01.png', 'base-sakura02.png', 'base-dango01.png', 'base-sakuramochi-cover.png', 'base-tulip01.png','base-tulip02.png','base-tulip03.png'],
    ['base-dango01.png', 'base-ichigo01.png'],
    ['bc-ichigo01.png', 'bc-sakura01.png', 'bc-sakura02.png', 'bc-dango01.png', 'bc-tulip01.png', 'bc-tulip02.png', 'bc-tulip03.png'],
    [],
    []
);

// 新作
const newPatternImages = [];
const newDecorationImages = ['deco-01.png'];
const newBasePatternImages = [];
const newBaseImages = [];
const newBcPatternImages = [];
const newBackgroundImages = [];
for (let i = 58; i <= 61; i++) {
  newPatternImages.push(`pt${i.toString()}.png`);
}
const newEvent = new ImageLists(
    'New',
    '2024-08-07',
    '2024-08-14',
    false,
    '✨NEW✨8/7動物とメガネ追加しました！',
    'bg-yellow-200 text-yellow-900',
    '✨NEW✨',
    'bg-yellow-200 text-yellow-900',
    newDecorationImages,
    newPatternImages,
    newBasePatternImages,
    newBaseImages,
    newBcPatternImages,
    newBackgroundImages,
    []
);

// 通常
const regularImages = new ImageLists(
  'Regular',
  null,
  null,
  false,
  '',
  '',
  'いつもの',
  'text-white',
  ['deco-01.png'],
  [
    'pt01.png', 'pt02.png', 'pt03.png', 'pt23.png', 'pt04.png', 'pt26.png', 'pt27.png', 'pt28.png', 'pt06.png', 'pt32.png', 'pt11.png',
    'pt12.png', 'pt30.png', 'pt31.png', 'pt13.png', 'pt14.png', 'pt16.png', 'pt17.png', 'pt18.png', 'pt25.png', 'pt29.png',
    'pt07.png', 'pt08.png', 'pt09.png', 'pt10.png', 'pt15.png', 'pt19.png', 'pt20.png', 
    'pt05.png', 'pt21.png',
    'pt22.png', 'pt24.png',
    'pt33.png', 'pt34.png', 'pt35.png', 'pt36.png', 'pt37.png', 'pt38.png', 'pt39.png', 'pt40.png', 'pt41.png', 'pt42.png',
    'pt43.png', 'pt44.png', 'pt45.png', 'pt46.png', 'pt47.png', 'pt48.png', 'pt49.png', 'pt50.png', 'pt51.png', 'pt52.png',
    'pt53.png', 'pt54.png', 'pt55.png', 'pt56.png', 'pt57.png',
    'pt58.png', 'pt59.png', 'pt60.png', 'pt61.png',
  ],
  [],
  [
    'base01.png','base02.png', 'base17.png', 'base04.png', 'base05.png', 'base06.png','base16.png', 'base07.png', 'base08.png', 'base09.png', 'base10.png',
    'base15.png', 'base11.png', 'base12.png', 'base13.png', 'base14.png'
  ],
  [
    'bc-flower01.png', 'bc-flower02.png', 'bc-flower03.png', 'bc-flower04.png', 'bc-flower05.png',
    'bc-check-01.png', 'bc-check-02.png', 'bc-check-03.png', 'bc-check-04.png', 'bc-check-05.png', 'bc-check-06.png', 'bc-check-07.png', 'bc-check-08.png', 
    'bc-check-09.png', 'bc-check-10.png', 'bc-check-11.png', 'bc-check-12.png', 'bc-check-13.png', 'bc-check-14.png', 'bc-check-15.png', 'bc-check-16.png'
  ],
  [
    'bc01.jpg', 'bc02.jpg', 'bc17.png', 'bc04.jpg', 'bc05.jpg', 'bc06.jpg', 'bc16.jpg', 'bc07.jpg', 'bc08.jpg', 'bc09.jpg', 'bc10.jpg',
    'bc15.jpg', 'bc11.jpg', 'bc12.jpg', 'bc13.png', 'bc14.png',
    'bc-img01.png', 'bc-img02.png',
    'grd-01.png', 'grd-02.png', 'grd-03.png', 'grd-04.png', 'grd-05.png', 'grd-06.png' 
  ],
  ['omikuji-daishi-01.png', 'omikuji-daishi-02.png', 'omikuji-daishi-03.png', 'omikuji-daishi-04.png', 'omikuji-daishi-05.png', 'omikuji-daishi-06.png', ]
);

const eventsData = [
  AutumnEvent, OtsukimiEvent,
  SummerEvent,
  firstAnnivEvent, firstAnnivTakarakujiEvent,
  tsuyuEvent, kodomonohiEvent,
  easterEvent, springEvent,
  newEvent, regularImages

];
export default eventsData;
export { firstAnnivTakarakujiEvent };