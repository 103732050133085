<template>
<div>
  <div class="flex justify-center space-x-4 mb-4">
    <a :href="getShareLink('taittsuu')"
      target="_blank"
      class="inline-flex items-center bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
      <img src="/images/taitsu-maker/kari_icon.png" alt="Share" class="w-4 h-4 mr-2">
      タイーツする
    </a>
    <!-- <a href="https://twitter.com/intent/tweet?text=アニマルタイツアイコンメーカー%20(by%20たやまゆうり @YuuriTayama_ )%20でタイツアイコンを作ったよ！&url=https://fun.yuuritayama.com&hashtags=アニマルタイツアイコンメーカー" -->
    <a :href="getShareLink(x)"
      target="_blank"
      class="inline-flex items-center bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded">
      Xでシェアする
    </a>
  </div>
  <div class="flex justify-center space-x-4 mb-12 text-xs">
    <p>※シェアするときに画像は自動でつかないので添付してください🙏</p>
  </div>
</div>
</template>

<script>
export default {
  name: 'Share',
  methods: {
    getShareLink(sns) {
      const route = this.$router.resolve({ name: 'TaitsuMaker' }).href;
      let baseLink;
      let message1;
      if (sns === "taittsuu") {
        baseLink = "https://taittsuu.com/share?text=";
        message1 = encodeURIComponent(" (by たやまゆうり) でタイツアイコンを作ったよ！");

      } else {
        baseLink = "https://twitter.com/intent/tweet?text=";
        message1 = encodeURIComponent(" (by たやまゆうり @YuuriTayama_ ) でタイツアイコンを作ったよ！");
      }
      const hashtag1 = encodeURIComponent("#アニマルタイツアイコンメーカー");
      const url = encodeURIComponent(`https://fun.yuuritayama.com${route}`);
      return `${baseLink}${hashtag1}${message1}%0A${url}`;
    }
  }
}
</script>
