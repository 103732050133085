<template>
  <div>
    <AnnivTakarakuji
    v-if="isFirstAnnivTakarakujiEventActive"
      @update:downloading="downloading = $event"
    />
    <!-- お知らせ欄: イベント情報を動的に表示 -->
    <div v-for="event in activeEvents" :key="event.eventName">
      <div :class="event.notificationColor + ' text-center py-4'">
        <p class="text-sm font-semibold">{{ event.notificationMessage }}</p>
      </div>
    </div>
    <!-- リアルアニマルタイツシール -->
    <div class="relative flex flex-col justify-center items-center mb-8 h-80 w-full bg-cover bg-center" style="background-image: url('/images/taitsu-maker/seal.jpg');">
      <p class="text-white text-xl font-bold bg-black bg-opacity-50 p-2 rounded mb-4">リアルアニマルタイツシール販売中！</p>
      <a href="https://shop.yuuritayama.com/items/87146680" target="_blank" class="bg-indigo-500 text-white font-bold py-2 px-4 rounded">Check!</a>
    </div>
    <ImagePreview
      :backgroundImage="selectedImages.background"
      :basePatternImage="selectedImages.basePattern"
      :baseImage="selectedImages.base"
      :bcPatternImage="selectedImages.bcPattern"
      :patternImage="selectedImages.pattern"
      :decorationImage="selectedImages.decoration"
    />
    <ImageSelector
      :events="events"
      @selected="handleImageSelected"
      @deselected="handleImageDeselected"
    />
    <ImageDownload
      :backgroundImage="selectedImages.background"
      :basePatternImage="selectedImages.basePattern"
      :baseImage="selectedImages.base"
      :bcPatternImage="selectedImages.bcPattern"
      :patternImage="selectedImages.pattern"
      :decorationImage="selectedImages.decoration"
      @update:downloading="downloading = $event"
    />
    <Share />
    <Ofuse />
    <TermsOfService />
    <!-- ローディングスピナーのオーバーレイ -->
    <div v-if="downloading" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div class="animate-spin rounded-full h-12 w-12 border-t-2 border-white"></div>
    </div>
  </div>
</template>

<script>
import ImageSelector from './ImageSelector.vue'
import ImagePreview from './ImagePreview.vue'
import ImageDownload from './ImageDownload.vue'
import AnnivTakarakuji from './AnnivTakarakuji.vue'
import Share from './Share.vue'
import Ofuse from './Ofuse.vue'
import TermsOfService from './TermsOfService.vue'
import { default as eventsData, firstAnnivTakarakujiEvent } from '@/data/TaitsuMaker/imageListsData';

export default {
  name: 'TaitsuMaker',
  metaInfo: {
    title: 'アニマルタイツアイコンメーカー',
    titleTemplate: "%s | Yuuri Tayama's Fun Box"
  },
  components: {
    ImageSelector,
    ImagePreview,
    ImageDownload,
    AnnivTakarakuji,
    Share,
    Ofuse,
    TermsOfService
  },
  data() {
    return {
      selectedImages: {
        decoration: '',
        pattern: '',
        basePattern: '',
        base: '',
        bcPattern: '',
        background: '',
      },
      downloading: false,
      events: eventsData,
    };
  },
  computed: {
    activeEvents() {
      return this.events.filter(event => event.eventName !== 'Regular' && event.isActive());
    },
    isFirstAnnivTakarakujiEventActive() {
      return firstAnnivTakarakujiEvent.isActive();
    }
  },
  methods: {
    handleImageSelected(type, imageUrl) {
        this.selectedImages[type] = imageUrl;
    },
    handleImageDeselected(type) {
      // 選択解除されたタイプの処理をここに実装
        this.selectedImages[type] = '';
    },
    isEventActive(eventName) {
      const event = this.events.find(e => e.eventName === eventName);
      return event ? event.isActive() : false;
    },
  },
  mounted() {
    document.title = "タイツアイコンメーカー | ";
  }
}
</script>
