<template>
  <div id="app">
    <Header />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/parts/Header.vue'
import Footer from '@/components/parts/Footer.vue'
export default {
  name: 'App',
  metaInfo: {
    title: "Yuuri Tayama's Fun Box",
  },
  components: {
    Header,
    Footer
  },
}
</script>

<style>
#app {
  font-family: Zen Maru Gothic, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
