<template>
  <div class="relative w-full h-400 bg-gray-200 mb-8 flex justify-center items-center">
    <!-- 背景画像を表示 -->
    <div v-if="backgroundImage" 
         class="absolute bg-no-repeat bg-center bg-contain w-full h-full"
         :style="{ 'background-image': 'url(/images/taitsu-maker/background/' + backgroundImage + ')' }">
    </div>
    <!-- 背景柄を表示 -->
    <div v-if="bcPatternImage" 
         class="absolute bg-no-repeat bg-center bg-contain w-full h-full"
         :style="{ 'background-image': 'url(/images/taitsu-maker/bcPattern/' + bcPatternImage + ')' }">
    </div>
    <!-- ベース画像を表示 -->
    <div v-if="baseImage" 
         class="absolute bg-no-repeat bg-center bg-contain w-full h-full"
         :style="{ 'background-image': 'url(/images/taitsu-maker/base/' + baseImage + ')' }">
    </div>
    <!-- 柄画像を表示 -->
    <div v-if="basePatternImage" 
         class="absolute bg-no-repeat bg-center bg-contain w-full h-full"
         :style="{ 'background-image': 'url(/images/taitsu-maker/basePattern/' + basePatternImage + ')' }">
    </div>
    <!-- 動物画像を表示 -->
    <div v-if="patternImage" 
         class="absolute bg-no-repeat bg-center bg-contain w-full h-full"
         :style="{ 'background-image': 'url(/images/taitsu-maker/pattern/' + patternImage + ')' }">
    </div>
    <!-- 飾り画像を表示 -->
    <div v-if="patternImage" 
         class="absolute bg-no-repeat bg-center bg-contain w-full h-full"
         :style="{ 'background-image': 'url(/images/taitsu-maker/decoration/' + decorationImage + ')' }">
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImagePreview',
  props: {
    decorationImage: String,
    patternImage: String,
    basePatternImage: String,
    baseImage: String,
    bcPatternImage: String,
    backgroundImage: String,
  },
}
</script>