<template>
  <div class="not-found">
    <h1>404</h1>
    <p>お探しのページは見つかりませんでした。</p>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
}
</script>

<style>
/* 404ページのスタイリング */
.not-found {
  text-align: center;
  margin-top: 50px;
}
</style>
