export const patternImages = {
    0: 'pt01.png',
    1: 'pt02.png',
    2: 'pt03.png',
    3: 'pt04.png',
    4: 'pt05.png',
    5: 'pt06.png',
    6: 'pt07.png',
    7: 'pt08.png',
    8: 'pt09.png',
    9: 'pt10.png',
    10: 'pt11.png',
    11: 'pt12.png',
    12: 'pt13.png',
    13: 'pt14.png',
    14: 'pt15.png',
    15: 'pt16.png',
    16: 'pt17.png',
    17: 'pt18.png',
    18: 'pt19.png',
    19: 'pt20.png',
    20: 'pt21.png',
    21: 'pt22.png',
    22: 'pt23.png',
    23: 'pt24.png',
    24: 'pt25.png',
    25: 'pt26.png',
    26: 'pt27.png',
    27: 'pt28.png',
    28: 'pt29.png',
    29: 'pt30.png',
    30: 'pt31.png',
    31: 'pt32.png'
};

export const decorationImages = {
    0: 'anniv-01.png',
    1: 'anniv-02.png',
};

export const basePatternImages = {
};

export const baseImages = {
    0: 'base11.png',
    1: 'base08.png',
    2: 'base15.png',
    3: 'base07.png',
    4: 'base09.png',
    5: 'base10.png',
    6: 'base12.png',
    7: 'base13.png',
    8: 'base14.png',
    9: 'base16.png',
    10: 'base17.png',
    11: 'base04.png',
    12: 'base05.png',
    13: 'base06.png',
    14: 'base01.png',
    15: 'base02.png',
};

export const bcPatternImages = {
    0: 'bc-anniv-06.png',
    1: 'bc-anniv-05.png',
    2: 'bc-anniv-04.png',
    3: 'bc-anniv-03.png',
    4: 'bc-anniv-02.png',
    5: 'bc-anniv-01.png',
};

export const backgroundImages = {
    0: 'bc02.jpg',
    1: 'bc16.jpg',
    2: 'bc17.png',
    3: 'bc04.jpg',
    4: 'bc05.jpg',
    5: 'bc06.jpg',
    6: 'bc07.jpg',
    7: 'bc08.jpg',
    8: 'bc09.jpg',
    9: 'bc10.jpg',
    10: 'bc15.jpg',
    11: 'bc11.jpg',
    12: 'bc12.jpg',
    13: 'bc13.png',
    14: 'bc14.png',
};


export const maxValues = {
    patternImages: Object.keys(patternImages).length - 1,
    decorationImages: Object.keys(decorationImages).length - 1,
    basePatternImages: Object.keys(basePatternImages).length - 1,
    baseImages: Object.keys(baseImages).length - 1,
    bcPatternImages: Object.keys(bcPatternImages).length - 1,
    backgroundImages: Object.keys(backgroundImages).length - 1
};

export const test = 3;