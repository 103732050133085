<template>
  <div>
    <!-- <div class="flex flex-col items-center my-8 w-full">
      <div class="container text-center">
        <h1 class="text-2xl font-bold mb-4">
          おみくじ
        </h1>
      </div>
    </div> -->
    <!-- お知らせ欄: イベント情報を動的に表示 -->
    <div v-for="event in activeEvents" :key="event.eventName">
      <div :class="event.notificationColor + ' text-center py-4'">
        <p class="text-sm font-semibold">{{ event.notificationMessage }}</p>
      </div>
    </div>
    <OmikujiImage
      :updateOmikujiImage="updateOmikujiImage"
      :updateIconImage="updateIconImage"
      :updateShareMessage="updateShareMessage"
    />
    <ImageDownload
      :backgroundImage="selectedImages.background"
      :basePatternImage="selectedImages.basePattern"
      :baseImage="selectedImages.base"
      :bcPatternImage="selectedImages.bcPattern"
      :patternImage="selectedImages.pattern"
      :decorationImage="selectedImages.decoration"
      @update:downloading="downloading = $event"
      :omikujiImage="omikujiImage"
      :generatedImage="omikujiImage" 
    />
    <Share :shareMessage="shareMessage" />
    <Ofuse />
    <TermsOfService />
    <!-- ローディングスピナーのオーバーレイ -->
    <div v-if="downloading" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div class="animate-spin rounded-full h-12 w-12 border-t-2 border-white"></div>
    </div>
  </div>
</template>

<script>
import OmikujiImage from './OmikujiImage.vue'
import ImageDownload from './ImageDownload.vue'
import Share from './Share.vue'
import Ofuse from '../TaitsuMaker/Ofuse.vue'
import TermsOfService from '../TaitsuMaker/TermsOfService.vue'
import { default as eventsData, firstAnnivTakarakujiEvent } from '@/data/TaitsuMaker/imageListsData';

export default {
  name: 'TaitsuMaker',
  metaInfo: {
    title: 'アニマルタイツアイコンメーカー',
    titleTemplate: "%s | Yuuri Tayama's Fun Box"
  },
  components: {
    OmikujiImage,
    ImageDownload,
    Share,
    Ofuse,
    TermsOfService
  },
  data() {
    return {
      selectedImages: {
        decoration: '',
        pattern: '',
        basePattern: '',
        base: '',
        bcPattern: '',
        background: '',
      },
      downloading: false,
      events: eventsData,
      omikujiImage: '',
      shareMessage: '',
    };
  },
  computed: {
    activeEvents() {
      return this.events.filter(event => event.eventName !== 'Regular' && event.isActive());
    },
    isFirstAnnivTakarakujiEventActive() {
      return firstAnnivTakarakujiEvent.isActive();
    }
  },
  methods: {
    handleImageSelected(type, imageUrl) {
        this.selectedImages[type] = imageUrl;
    },
    handleImageDeselected(type) {
      // 選択解除されたタイプの処理をここに実装
        this.selectedImages[type] = '';
    },
    isEventActive(eventName) {
      const event = this.events.find(e => e.eventName === eventName);
      return event ? event.isActive() : false;
    },
    updateOmikujiImage(imageUrl) {
      this.omikujiImage = imageUrl;
    },
    updateIconImage(images) {
      this.selectedImages.background = images.background;
      this.selectedImages.base = images.base;
      this.selectedImages.pattern = images.pattern;
    },
    updateShareMessage(message) {
      this.shareMessage = message;
    }
  },
  mounted() {
    document.title = "タイツアイコンメーカー | ";
  }
}
</script>
