<template>
  <div class="footer bg-gray-800 text-white mt-20 py-10">
    <div class="container mx-auto flex flex-col items-center md:flex-row md:justify-between">
      <div class="mb-6 md:mb-0">
        <img src="images/23040401.png" alt="プロフィールアイコン" class="rounded-full w-24 h-24 border-2 border-gray-700">
      </div>
      <div class="text-center md:text-left">
        <p class="text-xl font-semibold"><a class="underline" href="https://yuuritayama.com" target="blank">たやま ゆうり</a></p>
        <p class="text-gray-400">イラストレーター・作家 & システムエンジニア</p>
        <div class="flex justify-center md:justify-start space-x-4 mt-4">
          <a href="https://twitter.com/YuuriTayama_" class="hover:text-blue-700" target="blank">
            X
          </a>
          <a href="https://www.instagram.com/yuuritayama_/" class="hover:text-gray-400" target="blank">
            Instagram
          </a>
          <a href="https://bsky.app/profile/muguet.bsky.social" class="hover:text-gray-400" target="blank">
            Bluesky
          </a>
          <a href="https://taittsuu.com/users/muguet" class="hover:text-gray-400" target="blank">
            タイッツー
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>
