<template>
  <div class="flex flex-col justify-center items-center space-y-4 p-4 mb-12">
    <h2 class="text-xl font-bold text-gray-800">もしよかったら……</h2>
    <p class="text-gray-600 text-sm">
      アニマルタイツアイコンは個人開発でがんばっています✨<br />
      もし応援したいな～という方がいらっしゃいましたら<br />
      下のボタンから<b>「ファンレターを送る」</b>を<br />
      ポチッとしていただけるとうれしいです🌸<br />
      お手紙やお布施が送れます。<br />
      動物の種類や柄のリクエストなどもお待ちしております～💌
    </p>
    <a data-ofuse-widget-button href="https://ofuse.me/o?uid=66260" data-ofuse-id="66260" data-ofuse-color="blue-invert" data-ofuse-text="お手紙やお布施で応援" data-ofuse-style="rectangle">お布施やお手紙で応援</a>
  </div>
</template>

<script>
export default {
  name: 'Ofuse',
}
</script>
