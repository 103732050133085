class ImageLists {
  constructor(
    eventName, startDate, endDate, recurring, notificationMessage, notificationColor, decorationLabel, decorationClass,
    decorationImages, patternImages, basePatternImages, baseImages, bcPatternImages, backgroundImages,
    omikujiDaishiImages
  ) {
    this.eventName = eventName;
    this.startDate = startDate ? new Date(startDate) : null;
    this.endDate = endDate ? new Date(endDate) : null;
    this.recurring = recurring; // true: 毎年繰り返し, false: 一回限り
    this.notificationMessage = notificationMessage;
    this.notificationColor = notificationColor;
    this.decorationLabel = decorationLabel;
    this.decorationClass = decorationClass;
    this.decorationImages = decorationImages;
    this.patternImages = patternImages;
    this.basePatternImages = basePatternImages;
    this.baseImages = baseImages;
    this.bcPatternImages = bcPatternImages;
    this.backgroundImages = backgroundImages;
    this.omikujiDaishiImages = omikujiDaishiImages;
  }

  isActive() {
    const today = new Date();
    const currentYear = today.getFullYear();

    // 今日の日付のみ（時刻情報を無視）
    const todayDate = new Date(currentYear, today.getMonth(), today.getDate());

    if (this.startDate === null && this.endDate === null) {
      return true; // 常にアクティブ
    }
    if (this.recurring) {
      // 毎年繰り返す（月日のみで比較）
      const start = new Date(currentYear, this.startDate.getMonth(), this.startDate.getDate());
      const end = new Date(currentYear, this.endDate.getMonth(), this.endDate.getDate());
      if (start > end) {
        // 年をまたぐイベントの場合（例：12月から1月）
        if (today < start) {
          start.setFullYear(currentYear - 1);  // 昨年扱いで比較
        } else if (today > end) {
          end.setFullYear(currentYear + 1);  // 翌年扱いで比較
        }
      }
      return todayDate >= start && todayDate <= end;
    } else {
      // 一時期のみ（年月日で比較）
      const startDateOnly = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate());
      const endDateOnly = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate());
      return todayDate >= startDateOnly && todayDate <= endDateOnly;
    }
  }

  getDecoration() {
    // アクティブな時にのみ装飾情報を提供する
    if (this.isActive()) {
      return {
        label: this.decorationLabel,
        class: this.decorationClass
      };
    }
    return null;  // 非アクティブの場合は装飾なし
  }
}
export default ImageLists;