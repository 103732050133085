<template>
  <div class="flex flex-col justify-center items-center space-y-4 p-4">
    <h2 class="text-xl font-bold text-gray-800">利用規約</h2>
    <p class="text-gray-600 text-sm">
      個人利用の範囲内でお使いください。<br />ちょっとした加工はOKです。（文字入れ、飾りを加えるなど）
    </p>
    <p class="text-gray-600 text-sm">
      SNSアイコンとして使うときはシェアしてくれるとうれしいです。
    </p>
    <p class="text-gray-600 text-sm">
      こういう使い方してもいいのかな……？など疑問点があったら<br />
      <a class="underline text-pink-500" href="https://taittsuu.com/users/muguet" target="blank">たやまゆうり</a>
      に気軽にメンションしてください～
    </p>
  </div>
</template>

<script>
export default {
  name: 'TermsOfService',
}
</script>
