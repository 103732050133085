const OmikujiSentences = [
    '今日はいい日になるなぁと思っていると、いい日になるよ。どんなよいことがあるか楽しみだね。',
    'わたしたちは無限の可能性を持ってるよ。新しい可能性に目を向けて、夢に向かって前進することを楽しもうね。',
    'わたしたちは自分を無条件に肯定し、誇らしく感じていいんだよ。みんな理由もなく素晴らしいんだ。',
    '今日あったよかったこと、明日楽しみにしていることを見つける習慣をつけると、自尊心が高まっていくよ。',
    'よいことがあったら「自分らしいな」、イマイチだったら「自分らしくなかったな」。と言って、自己イメージを高めていこうね。',
    '失敗やミスをしたら、そのときのネガティブな感情や情景を反復せず、成功した清々しい感情や情景に置き換えちゃおうね。',
    'リラックスしていると前頭前野が優位になり、物事を冷静に判断できるよ。日頃から意識的にリラックスタイムを作ろうね。',
    'やりたいことをしているときがいちばん楽しい！何歳になってもやりたいことをしていいんだよ。',
    'やりたいことをしているあなたはとっても素敵！だれかのやりたいことを応援しているあなたもまた、すばらしいし素敵だよ。',
    '人間は自分が思い描いたイメージの実現に向かう性質があるんだ。ネガティブではなくポジティブなイメージを想像しようね。',
    'いつでもポジティブな言葉を自分にかけてあげようね。気分もよくなるし、考え方や行動がどんどんポジティブになっていくよ。',
    '他人のよいところを見つけていると、自分のよいところも見えてくる。他人も自分も、よいところをどんどん見つけてあげようね。',
    '褒められたら素直に受け取っていいんだよ。相手にお礼を言って、その人のいいところを伝えてあげたら、お互いにハッピーだね。',
    '「わたしは素晴らしい！最高！」と自分自身に声をかけてあげてね。理由もなく、あなたは素晴らしいんだよ。',
    '「あなたは素晴らしい！」「わたしたちは素晴らしい！」とお互いに認め合う世界はとてもハッピーだよね。',
    '過去ではなく未来に目を向けようね。ミスがあっても「次はどうしようか？」と問いかけて、未来を考えればいいんだよ。',
    '過去から自由になろうね。これから向かう、素晴らしく晴れ晴れとした未来のイメージを想像していこう！',
    '前向きで肯定的な言葉を自分にラッピングして、自己イメージを高めてあげようね。',
    '初めてできたこと、誇らしいこと、困難を乗り越えたことの映像を思い浮かべて、そのときの感情を何度も味わってみて。',
    'やりたいことが見つかるのは素晴らしいこと。やりたいなと思ったときに「でも……」と否定せず、まず受け止めてあげてね。',
    'いつでもゆったり深呼吸。大きく息を吸って全身に酸素を運ぶイメージ、吐く息とともにからだの力を抜いてゆるめてあげてね。',
    'どんな自分でいたいかな、どんな世界だったらハッピーかな、と想いを巡らせてみる日にしてみてね。',
    'もし嫌なことしんどいことを思い出しちゃったときは、無理に蓋をせず宇宙のはるか遠くの方に飛ばしてみてね。',
    '生活するだけでも元気は使うもの。やりたいことを考えられないときは、まずゆっくり休息して元気をチャージしようね。',
    '眉間がぎゅってなったり歯を食いしばったりしてるかも。リラックスに意識を集中してゆるめる時間を取ってみて。',
    '「今日も素晴らしい一日になるぞ！」と声に出してみてね。ポジティブなエネルギーで満ちあふれるよ。',
    'ハッピーな自分ってどんな状態かな？何度もイメージしてどんどん具体的にしてあげてね。',
    '今夜ねむる前に、今日あったよいことを3つ見つけてみて。小さなことでいいよ。よいことを探す習慣ができるのが大事なんだ。',
    '自分を大切にしていると、あなたを大切にしてくれる人たちに囲まれるようになるよ。まずは自身をやさしくケアしてあげてね。',
    '目標は具体的に書き出してみよう。その目標を達成したときのハッピーな気持ちを存分に味わってみて。',
    'ポジティブな言葉を使うことで、自分の考え方や行動もポジティブに変わっていくよ。前向きな言葉を選ぶようにしてみよう。',
    '何かを達成したら自分を褒めてあげよう。その積み重ねが自己肯定感を高め、次のチャレンジへの原動力になるよ。',
    '今日の空の色、雲の形、風のにおいはどんなかな？リラックスして自然を五感で感じてみよう。',
    'わたしたちの可能性は無限大。誰から何を言われても、やりたいことに制限をかける必要はないよ。',
    'お金や時間やリソースがすべて手に入るとしたら、どんなことを叶えてみたい？夢が叶ったハッピーな状態を想像し味わってみて。',
    '自分のよいところ、好きなところを10個以上挙げてみて。見つからないときは、1週間かけてじっくり探してみよう。',
    '憧れや尊敬する人の素敵なセリフを思い出してみて。自分のハッピーにつながりそうなものがあったら口癖にしてみよう。',
    '人から言われた言葉を思い出してみて。自己評価を下げるような言葉が出てきたら「いらない」と言って遠くに飛ばしちゃおう。',
    'リラックスタイムを作る日にしよう。心地よい音楽をじっくり聴いて、そこから自然と情景や光やにおいを感じてみてね。',
    '失敗体験を思い出したら、宇宙のはるか彼方にポイっと投げ捨てちゃおう。代わりにハッピーのイメージを膨らませてあげて。',
    '楽しくてハッピーで誇らしい自分の姿ってどんなだろう？じっくり考える日にしてみて。',
    'もう過去は気にしなくていいよ。一緒にハッピーな未来を作っていこうね。',
    'からだをゆらゆらして力を抜くことを意識する日にしよう。深い呼吸で胸を開いてあげるのも忘れずに。',
    '今日あなたに会えてうれしいな。おみくじを引いてくれる素敵なあなたの一日はとってもハッピーになるよ！',
];

export { OmikujiSentences };