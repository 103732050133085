<template>
  <div class="flex flex-col justify-center space-x-4 mb-8">
    <div class="bg-pink-200 text-pink-900">
      <p class="font-bold mt-8 mb-8 text-lg animate-text">#アニマルタイツアイコン宝くじ</p>
      <p class="mx-12 text-sm">応募期間：7/1 00:00 ~ 7/3 23:59</p>
      <p class="mx-12 text-sm">当選発表：7/5 17:00頃予定</p>
      <div>
        <input v-model="taitsuId" type="text" id="tights-id" class="border-2 border-gray-300 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 rounded-md py-2 px-4" placeholder="タイッツーID">
        <button @click="saveTaitsuIdAndGenerateImage" class="mt-4 bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded">
          宝くじ発行
        </button>
      </div>
      <div>
        <a href="https://taittsuu.com/share?text=%23アニマルタイツアイコン宝くじ %23アニマルタイツアイコンメーカー https://fun.yuuritayama.com"
          target="_blank"
          class="inline-flex items-center bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mt-8">
          <img src="/images/taitsu-maker/kari_icon.png" alt="Share" class="w-4 h-4 mr-2">
          タイーツして応募
        </a>
      </div>
      <!-- <p class="mt-8 mx-12 text-sm">①タイッツーIDを入力し「宝くじ発行」ボタンを押す</p>
      <p class="mx-12 text-sm">②DLされた画像を保存する</p>
      <p class="mx-12 text-sm">③タイッツーでハッシュタグ「#アニマルタイツアイコン宝くじ」をつける&画像を添付してタイーツ</p>
      <p class="mx-12 text-sm">④応募完了！当選発表をお待ちください</p> -->
      <p class="mt-8"><a class="font-bold text-indigo-500" href="https://taittsuu.com/users/muguet/status/31347552" target="blamk">こちら</a>から応募方法・利用規約をご確認ください。</p>
      <p class="mx-12 mt-4 mb-8 text-sm">応募時点で利用規約に同意したものとみなします。ご了承ください。</p>
    </div>
    <!-- 注意事項セクション -->
    <div class="bg-yellow-100 text-yellow-700 p-4" role="alert" style="margin: 0">
      <p class="font-bold text-xs">注意事項</p>
      <p class="text-xs">タイッツーIDは宝くじの発行と管理のために保存されます。保存されたデータはFirebaseのセキュリティ機能により保護され、第三者と共有されることはありません。</p>
      <p class="text-xs">アクセスが集中した場合、時間をおいて再度お試しください。</p>
    </div>
  </div>
</template>

<script>
import { db } from '../../../../firebase';
import { collection, query, where, runTransaction, doc, getDocs } from 'firebase/firestore';
import AnnivTakarakujiImageLists from '@/models/TaitsuMaker/AnnivTakarakujiImageLists'
import { patternImages, decorationImages, basePatternImages, baseImages, bcPatternImages, backgroundImages, maxValues } from '@/data/TaitsuMaker/AnnivTakarakujiImageListsData';

export default {
  name: 'AnnivTakarakuji',
  data() {
    return {
      taitsuId: '',
    };
  },
  methods: {
    async saveTaitsuIdAndGenerateImage() {
      // GA4でのイベントトラッキング
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'takarakuji', {
          'event_category': 'button',
          'event_label': 'downloadTakarakujiImg',
          'value': 1
        });
      }

      if (!this.taitsuId) {
        alert('タイッツーIDを入力してください');
        return;
      }
      const alphanumericRegex = /^[a-zA-Z0-9_]+$/;
      if (!alphanumericRegex.test(this.taitsuId)) {
        alert('タイッツーIDは半角英数字とアンダーバーのみで入力してください');
        return;
      }

      try {
        const today = new Date();
        const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        const endOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);

        // タイッツーIDの重複チェック（その日の範囲で）
        const parentDocRef = doc(db, 'FirstAnnivTakarakuji', 'Index');
        const ticketsCollectionRef = collection(parentDocRef, 'tickets');

        const q = query(
          ticketsCollectionRef,
          where('taitsuId', '==', this.taitsuId),
          where('timestamp', '>=', startOfToday),
          where('timestamp', '<=', endOfToday)
        );
        const querySnapshot = await getDocs(q);
            
        if (!querySnapshot.empty) {
          // 既存のデータがある場合
          const existingData = querySnapshot.docs[0].data();

          // 既存データを使用して画像を生成してダウンロード
          const existingImageList = new AnnivTakarakujiImageLists(
            existingData.taitsuId,
            existingData.timestamp,
            existingData.currentPatternIndex,
            existingData.currentBackgroundIndex,
            existingData.currentBaseIndex,
            existingData.currentDecorationIndex,
            existingData.currentBcPatternIndex,
            existingData.currentBasePatternIndex
          );

          const existingImageBlob = await this.generateImageBlob(existingImageList);
          if (existingImageBlob) {
            this.downloadImage(existingImageBlob);
          }

          alert('宝くじを発行しました。画像を保存して「#アニマルタイツアイコン宝くじ」でタイーツしてね。※画像添付を忘れずに！');

        } else {
          await runTransaction(db, async (transaction) => {
            // 最新のインデックスを取得
            const indexDocRef = doc(db, 'FirstAnnivTakarakuji', 'Index');
            const indexDocSnapshot = await transaction.get(indexDocRef);

            if (!indexDocSnapshot.exists()) {
              throw new Error('Index document does not exist');
            }

            const indexData = indexDocSnapshot.data();

            // 新規データの保存
            const annivTakarakujiImageList = new AnnivTakarakujiImageLists(
              this.taitsuId, new Date(),
              indexData.nextPatternIndex,
              indexData.nextBackgroundIndex,
              indexData.nextBaseIndex,
              indexData.nextDecorationIndex,
              indexData.nextBcPatternIndex,
              indexData.nextBasePatternIndex
            );

            const result = annivTakarakujiImageList.incrementImageNum({
              currentPatternIndex: indexData.nextPatternIndex,
              currentBackgroundIndex: indexData.nextBackgroundIndex,
              currentBaseIndex: indexData.nextBaseIndex,
              currentDecorationIndex: indexData.nextDecorationIndex,
              currentBcPatternIndex: indexData.nextBcPatternIndex,
              currentBasePatternIndex: indexData.nextBasePatternIndex
            }, maxValues);

            if (!result) {
              alert('宝くじ発行は終了しました。');
              return;
            }

            // 画像生成とダウンロード
            const imageBlob = await this.generateImageBlob(annivTakarakujiImageList);

            if (!imageBlob) {
              throw new Error("画像の生成に失敗しました。");
            }

            // Firestoreにデータを保存
            const newDocRef = doc(ticketsCollectionRef); // 修正: 正しいドキュメント参照を作成
            transaction.set(newDocRef, { 
              taitsuId: this.taitsuId,
              timestamp: new Date(),
              currentPatternIndex: annivTakarakujiImageList.currentPatternIndex,
              currentBackgroundIndex: annivTakarakujiImageList.currentBackgroundIndex,
              currentBaseIndex: annivTakarakujiImageList.currentBaseIndex,
              currentDecorationIndex: annivTakarakujiImageList.currentDecorationIndex,
              currentBcPatternIndex: annivTakarakujiImageList.currentBcPatternIndex,
              currentBasePatternIndex: annivTakarakujiImageList.currentBasePatternIndex,
            });

            // インデックスの更新
            transaction.update(indexDocRef, {
              nextPatternIndex: annivTakarakujiImageList.currentPatternIndex,
              nextBackgroundIndex: annivTakarakujiImageList.currentBackgroundIndex,
              nextBaseIndex: annivTakarakujiImageList.currentBaseIndex,
              nextDecorationIndex: annivTakarakujiImageList.currentDecorationIndex,
              nextBcPatternIndex: annivTakarakujiImageList.currentBcPatternIndex,
              nextBasePatternIndex: annivTakarakujiImageList.currentBasePatternIndex
            });

            this.downloadImage(imageBlob);

            alert('宝くじを発行しました。画像を保存して「#アニマルタイツアイコン宝くじ」でタイーツしてね。※画像添付を忘れずに！');
          });
        }

      } catch (e) {
        console.error('Error adding document: ', e);
        alert('宝くじ発行に失敗しました。時間をおいて試してみてください。');
      }
    },
    async generateImageBlob(imageList) {
      this.downloading = true;
      this.$emit('update:downloading', true);

      try {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = 800;
        canvas.height = 800;

        // firebaseに保存した画像IDに対応する画像をpushする
        // IDとの対応はAnnivTakarakujiImageListsを使う
        const imageSources = {
          background: backgroundImages[imageList.currentBackgroundIndex],
          bcPattern: bcPatternImages[imageList.currentBcPatternIndex],
          base: baseImages[imageList.currentBaseIndex],
          basePattern: basePatternImages[imageList.currentBasePatternIndex],
          pattern: patternImages[imageList.currentPatternIndex],
          decoration: decorationImages[imageList.currentDecorationIndex]
        };

        await this.loadImage(ctx, `/images/taitsu-maker/background/${imageSources.background}`, 0, 0, canvas.width, canvas.height);
        await this.loadImage(ctx, `/images/taitsu-maker/bcPattern/${imageSources.bcPattern}`, 0, 0, canvas.width, canvas.height);
        await this.loadImage(ctx, `/images/taitsu-maker/base/${imageSources.base}`, 0, 0, canvas.width, canvas.height);
        // await this.loadImage(ctx, `/images/taitsu-maker/basePattern/${imageSources.basePattern}`, 0, 0, canvas.width, canvas.height);
        await this.loadImage(ctx, `/images/taitsu-maker/pattern/${imageSources.pattern}`, 0, 0, canvas.width, canvas.height);
        await this.loadImage(ctx, `/images/taitsu-maker/decoration/${imageSources.decoration}`, 0, 0, canvas.width, canvas.height);

        // タイッツーIDを描画
        ctx.font = '30px Arial';
        ctx.fillStyle = 'black';
        ctx.fillText(`タイッツーID: ${this.taitsuId}`, 50, 750);

        // Indexを描画
        const padZero = (num) => String(num).padStart(2, '0');
        const indexString = padZero(imageList.currentBcPatternIndex) + 
                            padZero(imageList.currentDecorationIndex) + 
                            padZero(imageList.currentBaseIndex) + 
                            padZero(imageList.currentBackgroundIndex) + 
                            padZero(imageList.currentPatternIndex);
        ctx.font = '20px Arial';
        ctx.fillStyle = 'black';
        ctx.fillText(indexString, 50, 30);

        // 現在日時を描画
        const currentDate = new Date();
        const dateString = currentDate.toLocaleString();
        ctx.fillText(dateString, 50, 60);

        const mimeType = 'image/jpeg';
        return new Promise((resolve, reject) => {
          canvas.toBlob(blob => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error("Blob生成に失敗しました。"));
            }
          }, mimeType);
        });
      } catch (error) {
        console.error("Error generating image: ", error);
        return null;
      } finally {
        this.downloading = false;
        this.$emit('update:downloading', false);
      }
    },
    loadImage(ctx, src, x, y, width, height) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => {
          ctx.drawImage(image, x, y, width, height);
          resolve();
        };
        image.onerror = reject;
        image.src = src;
      });
    },
    downloadImage(blob) {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'animal-taitsu-icon-takarakuji.jpg';
      a.click();
      URL.revokeObjectURL(url);
    },
    showSuccessMessage() {
      // alert('ダウンロードに成功したよ！');
    },
    showErrorMessage() {
      // alert('ダウンロードに失敗しました。');
    }
  }
}
</script>

<style scoped>
@keyframes fadeSlideIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes textPulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.animate-text {
  animation: textPulse 2s ease-in-out infinite;
}
</style>