import Vue from 'vue'
import Router from 'vue-router'
// import Home from '@/components/pages/Home/Index.vue'
import TaitsuMaker from '@/components/pages/TaitsuMaker/Index.vue'
import TaitsuOmikuji from '@/components/pages/TaitsuOmikuji/Index.vue'
import NotFound from '@/components/pages/NotFound.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'TaitsuMaker',
      component: TaitsuMaker,
      meta: {
        title: 'アニマルタイツアイコンメーカー',
        metaTags: [
          {
            name: 'description',
            content: 'アニマルタイツアイコンメーカーで自分だけのタイツアイコンを作りましょう！'
          }
        ]
      }
    },
    {
      path: '/shrine/omikuji',
      name: 'Omikuji',
      component: TaitsuOmikuji,
      meta: {
        title: 'アニマルタイツ神社 - おみくじ',
        metaTags: [
          {
            name: 'description',
            content: 'アニマルタイツア神社でおみくじをひいて、今日のあなたにぴったりなタイツと出会いましょう。'
          }
        ]
      }
    },
    {
      path: '*',
      name: 'NotFound',
      component: NotFound,
    },
  ],
})
